
import {defineComponent, onMounted, reactive, toRefs, computed} from "vue";
import ApiLogistics from "@/request/apis/api_logistics";
import router from "@/router";
import {message} from "ant-design-vue/es";
import StorageUtil from "@/utils/storage_util";
import PageUtil from "@/utils/page_util";
import {IComplaintItem} from "@/models/logistics_model";
import {dateFormat} from "@/utils/common";
import {
  getOrderMode,
  getPayMethod,
  getPayStatus,
  getShipMethod,
  getLogisticsOrderStatus,
} from "@/utils/status";

interface IState {
  list: IComplaintItem[],
  total: number;
  loading: boolean;
  searches: any
  visible: boolean
  type?: 1 | 2, // 1提现审核，2打款确认
}

// 表格
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '投诉人（货主）',
    dataIndex: 'user',
    key: 'user',
  },
  {
    title: '被投诉司机',
    dataIndex: 'courier',
    key: 'courier',
  },
  {
    title: '订单编号',
    dataIndex: 'sn',
    key: 'sn',
  },
  {
    title: '订单状态',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: '订单模式',
    dataIndex: 'mode',
    key: 'mode',
  },
  {
    title: '金额信息(元)',
    dataIndex: 'money',
    key: 'money',
  },
  {
    title: '支付信息',
    dataIndex: 'pay_status',
    key: 'pay_status',
  },
  {
    title: '接单信息',
    dataIndex: 'rob_time',
    key: 'rob_time',
  },
  {
    title: '操作',
    key: 'action',
    width: 250
  },
];

export default defineComponent({
  name: 'ComplaintList',
  setup() {
    const state: IState = reactive({
      list: [],
      total: 0,
      loading: false,
      visible: false,
      searches: {
        page_no: PageUtil.pageNo,
        page_size: PageUtil.pageSize,
      },
    })

    const pagination = computed(() => ({
      total: state.total,
      current: state.searches.page_no,
      pageSize: state.searches.page_size,
      showTotal: (total: any) => `共${total}条数据`,
      defaultPageSize: 10,
      // pageSizeOptions: ['5', '10', '15', '20'], // 可不设置使用默认
      showSizeChanger: true, // 是否显示pageSize选择
      showQuickJumper: true, // 是否显示跳转窗
    }));

    // 列表当前页更改
    const handleTableChange = (pages: any) => {
      state.searches.page_size = pages.pageSize
      state.searches.page_no = pages.current
      getList()
    };

    //获取列表
    const getList = async () => {
      let params = {...state.searches}
      StorageUtil.setParams(params)
      state.loading = true
      const res = await ApiLogistics.getComplaintList(params)
      state.loading = false
      if (res && res.error_code === 0) {
        state.total = res.data?.count || 0
        state.list = res.data?.list || []
      }
    }

    // 去详情
    const goDetail = (item: IComplaintItem, type: 'complaint' | 'order' = 'complaint') => {
      if(type === 'complaint'){
        // 去投诉详情
        router.push({path: '/complaint/detail', query: {id: item.id}}).then()
      }else{
        // 去订单详情
        router.push({path: '/logistics_order/order_details', query: {id: item.id}}).then()
      }
    }

    // 搜索
    const onSearch = (key: string, value: any, e: any) => {
      state.searches[key] = value
      state.searches.page_no = 1
      state.searches.page_size = PageUtil.pageSize
      getList()
    }

    // 重置search参数
    const resetSearch = () => {
      for (let key in state.searches) {
        state.searches[key] = ''
      }
      state.searches.page_no = 1
      state.searches.page_size = PageUtil.pageSize
      getList()
    }

    // 初始化search参数
    const initSearch = () => {
      const res = StorageUtil.getParams()
      if (res) {
        state.searches = {...state.searches, ...res}
      }
    }

    onMounted(() => {
      initSearch()
      getList()
    })

    return {
      ...toRefs(state),
      columns,
      pagination,
      handleTableChange,
      onSearch,
      resetSearch,
      dateFormat,
      getLogisticsOrderStatus,
      getPayStatus,
      getPayMethod,
      getShipMethod,
      getOrderMode,
      goDetail,
    }
  }
})
